import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../Layout'
import Logo from '../components/Logo'
import Slider from '../components/Slider'

const Index2Page = () => {
  const [projectIndex, setProjectIndex] = useState(0)
  const projectsData = [
    {
      slug: 'https://illustrators.pl/',
      name: 'Illustrators',
      themeColor: '#ffdaba',
      secondaryColor: '#000000',
      award: 'https://www.awwwards.com/sites/illustrators',
      mobileAward: 'https://www.awwwards.com/sites/illustrators',
    },
    {
      slug: 'https://trends.aax.com/crypto-gods/',
      name: 'AAX Crypto Gods',
      themeColor: '#1A1A1A',
      secondaryColor: '#0031ff',
      award: true,
      mobileAward: false,
    },
    {
      slug: 'https://hellomola.com',
      name: 'Mola',
      themeColor: '#ff6f61',
      secondaryColor: '#ffffff',
      award: false,
      mobileAward: false,
    },
    {
      slug: 'https://www.filmspowiedz.pl/',
      name: 'Spowiedz the movie',
      themeColor: '#0A0A0A',
      secondaryColor: '#ffffff',
      award: false,
      mobileAward: false,
    },
    {
      slug: 'https://gravitywarsaw.com/',
      name: 'Gravity Warsaw',
      themeColor: '#2F2DFB',
      secondaryColor: '#ffffff',
      award: 'https://www.awwwards.com/sites/gravity-warsaw',
      mobileAward: false,
    },
    {
      slug: 'https://theironfairies.com/',
      name: 'The Iron Fairies',
      themeColor: '#2D2D2D',
      secondaryColor: '#ffcc00',
      award: false,
      mobileAward: false,
    },
  ]
  const general = {
    url: 'https://www.kobylarz.co',
    name: 'kobylarz.co',
    address: 'kobylarz.co',
    logoHeader: 'Przemek Kobylarz',
    logoSubheader: 'Creative Frontend Developer',
    title: 'Przemek Kobylarz | Creative Frontend Developer',
    description: `Let's make something cool together.`,
    author: '@pkobylarz',
    keywords: [
      'przemek',
      'przemyslaw',
      'kobylarz',
      'frontend',
      'developer',
      'programmer',
      'designer',
      'warsaw',
      'poland',
      'bojanow',
      'user',
      'experience',
      'interface',
    ],
  }

  return (
    <Layout colorTheme={projectsData[projectIndex].themeColor}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={general.title}
        meta={[
          { name: `description`, content: general.description },
          { property: `og:title`, content: general.title },
          { property: `og:description`, content: general.description },
          { property: `og:type`, content: `website` },
          // { name: `og:image`, content: shareImage },
          { name: `twitter:card`, content: `summary` },
          { name: `twitter:creator`, content: general.author },
          { name: `twitter:title`, content: general.title },
          { name: `twitter:description`, content: general.description },
        ].concat(
          general.keywords.length > 0
            ? { name: `keywords`, content: general.keywords.join(`, `) }
            : []
        )}
      />
      <Logo header={general.logoHeader} subheader={general.logoSubheader} />
      <Slider projects={projectsData} />
    </Layout>
  )
}

export default Index2Page
